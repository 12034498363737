<div class="container">
    <h1>Testimonials</h1>
    <div class="testimonial">
      <p>Hi Julie: Thank you for again donating a gift certificate to “Lake Holcombe Golfers, Swinging Fore A Cause”!!
        We bought the certificate last year, and were SO happy with our experience with you. At this year’s golf outing,
        we offered to display our water color portrait, so guests could see the quality of your work. People were so impressed!!
        I know the lady who won the bid on your gift certificate really wanted it, and made sure she got it :))</p>
      <p>We look forward to doing more work with you in the future. Again – – – Thank You!!</p>
      <p class="testimonial-signature">– Jeanne</p>
    </div>
    <div class="testimonial">
      <p>I just cried at work. They are beautiful. I’ll give you a call. Again, thank you for everything.</p>
      <p class="testimonial-signature">– Melissa</p>
    </div>
    <div class="testimonial">
      <p>Thank you for a wonderful Mother-Daughter day! You are AWESOME!!!</p>
      <p class="testimonial-signature">– Hollie</p>
    </div>
    <div class="testimonial">
      <p>Hi Julie Rae and Curt, Just wanted to say thank you so much for all the wonderful time we had when Ashley’s pictures were taken.
        Looking forward to seeing them online. GREAT JOB WITH PICS, U GUYS ARE THE BEST!!!!</p>
      <p class="testimonial-signature">– Jeanne</p>
    </div>
    <div class="testimonial">
      <p>Wow! I honestly love all of them…they all look just wonderful!! Including the art work you added to them.
        Again thanks so much i had a blast, and i will get in touch with you sometime soon!!!!</p>
      <p class="testimonial-signature">– Teresa</p>
    </div>
    <div class="testimonial">
      <p>Thanks Julie Rae! These pictures are FANTASTIC!! It is going to be hard to choose that is for sure.
        Kirsten is gone on a church trip this week and will be back next Monday so she probably won’t be able to view them until then.
        Thanks again and have a GREAT day!</p>
      <p class="testimonial-signature">– Shari</p>
    </div>
    <div class="testimonial">
      <p>Julie Rae, Thanks so much for the positive senior picture taking experience! Your patience, professionalism,
        and eye to get the right “look” were awesome! Can’t wait to see them on-line. Thanks again!</p>
      <p class="testimonial-signature">– Kirsten and Shari Bowers</p>
    </div>
    <div class="testimonial">
      <p>Julie Rae – these are really amazing. You did a wonderful job. I had a fun time at our session.
        Thank you so much!</p>
      <p class="testimonial-signature">– Jenni C</p>
    </div>
    <div class="testimonial">
      <p>To the staff at “The Look” Photography ~</p>
      <p>Thank you for taking my senior pictures & everything else you did. I love them & I also really
        like how my graduation announcements turned out! I’ve received many wonderful comments on both my
        pictures & announcements. Thank you very much for everything!</p>
      <p class="testimonial-signature">– Breanna G</p>
    </div>
    <div class="testimonial">
      <p>I LOVE THEM! Thanks</p>
      <p class="testimonial-signature">– Ahna B</p>
    </div>
    <div class="testimonial">
      <p>The pictures look fabulous. Thanks again for all you did.</p>
      <p class="testimonial-signature">– Sue</p>
    </div>
    <div class="testimonial">
      <p>Julie Rae ~ Thank you very much for doing such a WONDERFUL Job! Aziz and I really enjoyed working with you.
        When you told me you had the weekend of our wedding open, I was so excited!!! Keep up the great work.
        When we have our first baby, guess who’s our photographer!!! You! We Love Ya Babe!</p>
      <p class="testimonial-signature">– Laura and Aziz</p>
    </div>
</div>