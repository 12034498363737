<header>
    <div class="container">
        <a routerLink="/" class="logo">
            <img src="../../assets/the_look_logo.gif" alt="The Look Photography">
        </a>
        <button class="menu-button" (click)="toggleMenu()">Menu</button>
        <div [ngClass]="menuOpen ? 'open' : 'closed'">
            <app-navigation></app-navigation>
        </div>
    </div>
</header>
