<div class="container">
  <h1>About Julie Rae</h1>
  <div class="bio">
    <div class="left">
      <img class="bio-pic" src="../../assets/JR_photo.jpg" alt="Julie Rae Harrison">
    </div>
    <div class="right">
      <p>Julie Rae’s passion for photographic art can be seen graphically in her images. A 15 year full-time professional,
        she has been recognized for her elegant and dramatic images in local, state and national professional
        photographic competitions.</p>
      <ul>
        <li>Fuji Masterpiece Award 2006</li>
        <li>Kodak Gallery Award 2010</li>
        <li>Silver Photographer of the Year 2006 & 2009</li>
        <li>Bronze Photographer of the Year 2007</li>
        <li>PP of A “Master of Photography” 2011</li>
      </ul>
    </div>
    <p>Her dream became real when Julie Rae and Curt Harrison opened “The Look” Photography, in 2004, in the cozy town of Bloomer, Wisconsin.
      Julie Rae studied under and received valuable training and experience from her established mentors and continuous membership in
      professional photographic associations.</p>
    <p>Julie Rae is a people-person and loves interacting with her clients. You will be able to sense her charismatic and outgoing personality
      from the first time you get a change to be in the same room as her. Her personality is a key aspect that helps create the atmosphere to
      produce one of a kind portraits. She enjoys meeting and working with every single one of her clients, in all genres of photography.
      Julie Rae feels blessed by the wonderful friendships she has developed with her clientele over the years.</p>
  </div>
</div>